<template>
    <div class="edit">
        <p class="edit-wechat-title">企业微信推送配置</p>
        <div class="edit-wechat-configuration">
            <div class="edit-wechat-configuration-association">
                <p class="edit-wechat-configuration-association-title">关联的企业微信群webhook：</p>
                <el-input class="edit-wechat-configuration-association-content" v-model="weChatAssociation"></el-input>
            </div>
            <div class="edit-wechat-configuration-pdf">
                <p class="edit-wechat-configuration-pdf-title">发送PDF文件：</p>
                <el-switch class="edit-wechat-configuration-pdf-switch" v-model="isPushPDF" active-color="#6DA4D8"
                    inactive-color="#DCDFE6"></el-switch>
            </div>
            <div class="edit-wechat-configuration-word">
                <p class="edit-wechat-configuration-word-title">发送文字介绍：</p>
                <el-switch class="edit-wechat-configuration-word-switch" v-model="isPushWord" active-color="#6DA4D8"
                    inactive-color="#DCDFE6"></el-switch>
                    <p class="edit-wechat-configuration-word-tip">例如：小明2月9日报告已生成，请查收！</p>
            </div>
            <div class="edit-wechat-configuration-frequency">
                <p class="edit-wechat-configuration-frequency-title">推送频次：</p>
                <el-select class="edit-wechat-configuration-frequency-content" v-model="weChatPushValue">
                    <el-option v-for="item in weChatPushOptions" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </div>
        </div>
        <p class="edit-message-title">短信推送配置</p>
        <div class="edit-message-configuration">
            
            <div class="edit-message-configuration-association">
                <p class="edit-message-configuration-association-title">关联的短信模版：</p>
                <el-select class="edit-message-configuration-association-content" v-model="messagePushValue">
                    <el-option v-for="item in messagePushOptions" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </div>
            <div class="edit-message-configuration-frequency">
                <p class="edit-message-configuration-frequency-title">推送频次：</p>
                <el-select class="edit-message-configuration-frequency-content" v-model="messageFrequencyPushValue">
                    <el-option v-for="item in messageFrequencyPushOptions" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </div>

        </div>
        <div class="edit-buttons">
            <div class="edit-buttons-container">
                <button class="edit-buttons-container-cancel" @click="onCancelClick">取消</button>
                <button class="edit-buttons-container-save" @click="onSaveClick">保存</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        model: Object
    },
    data() {
        return {
            weChatAssociation: "",
            isPushPDF: false,
            isPushWord: false,
            weChatPushValue: "0",
            messagePushValue: "14",
            messageFrequencyPushValue: "0",
            weChatPushOptions: [
                {
                    value: "0",
                    label: '关闭',
                },
                {
                    value: "1",
                    label: '仅完整报告第一次生成时推送',
                },
                {
                    value: "2",
                    label: '生成完整报告和报告更新时都推送',
                },
            ],
            messagePushOptions: [
                {
                    value: "14",
                    label: '温馨提醒，您的监测已结束，请及时将设备归还至医院以便出具报告！',
                },

            ],

            messageFrequencyPushOptions: [
                {
                    value: "0",
                    label: '关闭',
                },
                {
                    value: "1",
                    label: '仅完整报告第一次生成时推送',
                },
                {
                    value: "2",
                    label: '生成完整报告和报告更新时都推送',
                },
            ],
        }
    },

    mounted() {

        this.weChatAssociation = this.model.weixinWebhookUrl
        if (this.model.weixinWebhookSendPdf == "1") {
            this.isPushPDF = true
        } else {
            this.isPushPDF = false
        }

        if (this.model.weixinWebhookSendText == "1") {
            this.isPushWord = true
        } else {
            this.isPushWord = false
        }

        if (this.model.weixinWebhookFrequentType != "0" && this.model.weixinWebhookFrequentType != null) {
            this.weChatPushValue = this.model.weixinWebhookFrequentType
        } else {
            this.weChatPushValue = "0"
        }


        if (this.model.smsFrequentType != "0" && this.model.smsFrequentType != null) {
            this.messageFrequencyPushValue = this.model.smsFrequentType
        } else {
            this.messageFrequencyPushValue = "0"
        }

        if (this.model.smsTemplateId == null) {
            this.messagePushValue = "14"
        }else {
            this.messagePushValue = this.model.smsTemplateId
        }
         

        
    },

    methods: {
        onCancelClick() {
            this.$emit("onCancelClick");
        },
        onSaveClick() {
            const settingInfoModel = {
                hospitalId : 0,
                weixinWebhookUrl: this.weChatAssociation,
                weixinWebhookFrequentType: this.weChatPushValue,
                weixinWebhookSendPdf : this.isPushPDF ? "1" : "0",
                weixinWebhookSendText : this.isPushWord ? "1" : "0",
                smsTemplateId: this.messagePushValue,
                smsFrequentType: this.messageFrequencyPushValue
            };
            this.$emit("onSaveClick", settingInfoModel);
        }
    }
}
</script>


<style lang="scss" scoped>
.edit {
    background-color: white;
    width: 100%;
    position: relative;

    &-wechat-title {
        color: black;
        font-size: rem(16);
        font-weight: bold;
        height: rem(22);
        margin-top: rem(16);
    }

    &-wechat-configuration {
        margin-top: rem(21);

        font-size: rem(14);
        color: black;

        &-association {
            height: rem(32);
            display: flex;
            align-items: center;

            &-title {
                width: rem(199);
            }

            &-content {
                width: rem(590);

                ::v-deep .el-input__inner {
                    color: black;
                    height: rem(32);
                    padding-left: rem(12);
                    padding-right: rem(12);
                    font-size: rem(14);
                }
            }
        }

        &-pdf {
            margin-top: rem(10);
            display: flex;

            &-switch {
                transform: scale(1.3);
                margin-left: rem(6);
                width: rem(52);
                height: rem(26);
            }

            &-title {
                height: rem(26);
                width: rem(199);
                display: flex;
                align-items: center;
            }
        }

        &-word {
            margin-top: rem(10);
            display: flex;
            align-items: center;

            &-switch {
                transform: scale(1.3);
                margin-left: rem(6);
                width: rem(52);
                height: rem(26);
            }

            &-title {
                height: rem(26);
                width: rem(199);
                display: flex;
                align-items: center;
            }

            &-tip {
                margin-left: rem(40);
            }
        }

        &-frequency {
            margin-top: rem(10);
            height: rem(32);
            display: flex;
            align-items: center;

            &-title {
                width: rem(199);
            }

            &-content {
                width: rem(590);

                ::v-deep .el-input__inner {
                    color: black;
                    height: rem(32);
                    padding-left: rem(12);
                    padding-right: rem(12);
                    font-size: rem(14);
                }

                ::v-deep .el-input__suffix {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &-message-title {
        color: black;
        font-size: rem(16);
        font-weight: bold;
        height: rem(22);
        margin-top: rem(20);
    }

    &-message-configuration {
        font-size: rem(14);
        color: black;

        &-association {
            margin-top: rem(15);
            display: flex;
            align-items: center;
            height: rem(32);

            &-title {
                width: rem(199);
            }

            &-content {
                width: rem(590);

                ::v-deep .el-input__inner {
                    color: black;
                    height: rem(32);
                    padding-left: rem(12);
                    padding-right: rem(12);
                    font-size: rem(14);
                }

                ::v-deep .el-input__suffix {
                    display: flex;
                    align-items: center;
                }
            }
        }

        &-frequency {
            margin-top: rem(10);
            height: rem(32);
            display: flex;
            align-items: center;

            &-title {
                width: rem(199);
            }

            &-content {
                width: rem(590);

                ::v-deep .el-input__inner {
                    color: black;
                    height: rem(32);
                    padding-left: rem(12);
                    padding-right: rem(12);
                    font-size: rem(14);
                }

                ::v-deep .el-input__suffix {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(63);

        &-container {
            display: flex;

            &-cancel {
                color: #325FAC;
                font-size: rem(16);
                border: rem(1) solid #325FAC;
                border-radius: rem(4);
                width: rem(100);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-save {
                color: white;
                background-color: #325FAC;
                font-size: rem(16);
                border: rem(1) solid #325FAC;
                border-radius: rem(4);
                width: rem(100);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: rem(50);
            }
        }
    }

}</style>